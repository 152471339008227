import React from 'react'

export default function(props) {
	let color = props.color ||'black'
	let style = {
		fill: color
	}
	let localStyle
	switch (props.name) {
		case 'arrow-down':
			localStyle = {
				pointerEvents: 'none',
				display: 'block'
			}
			return (
				<svg viewBox="0 0 24 24"focusable="false" style={localStyle}>
					<g><path style={style} className="st0" d="M7 10l5 5 5-5z"></path></g>
				</svg>
			)
		case 'facebook':
			return (
				<svg x="0px" y="0px" viewBox="0 0 30 30" xmlSpace="preserve">
					<path style={style} d="M23.3,5.2h-4.8c-0.6,0-1.2,0.7-1.2,1.7v3.4h6v4.9h-6V30h-5.6V15.3H6.7v-4.9h5.1V7.5c0-4.1,2.9-7.5,6.8-7.5h4.8V5.2z"/>
				</svg>
			)
		case 'vimeo':
			return (
				<svg x="0px" y="0px" viewBox="0 0 30 30">
				<path style={style} d="M29.9,8.1c-1.7,9.6-11,17.8-13.9,19.6c-2.8,1.9-5.4-0.7-6.3-2.7C8.6,22.7,5.4,10.6,4.6,9.5c-0.8-1-3.4,1-3.4,1L0,8.9c0,0,5.1-6.2,9-7c4.1-0.8,4.1,6.4,5.1,10.5c1,3.9,1.6,6.1,2.4,6.1c0.8,0,2.4-2.2,4.2-5.5c1.8-3.3-0.1-6.3-3.5-4.2
					C18.6,0.5,31.5-1.6,29.9,8.1z"/>
				</svg>
			)
		case 'instagram':
			return (
				<svg x="0px" y="0px" viewBox="0 0 30 30">
				<path style={style} d="M24.1,14.9c0,5-4.1,9.1-9.1,9.1c-5,0-9.1-4.1-9.1-9.1c0-0.6,0.1-1.2,0.2-1.7H0v12.1c0,2.6,2.1,4.7,4.7,4.7h20.6c2.6,0,4.7-2.1,4.7-4.7V13.2h-6.1C24,13.8,24.1,14.3,24.1,14.9z M25.3-0.1H4.7C2.1-0.1,0,2,0,4.6v5.1h7.6c1.6-2.3,4.3-3.8,7.4-3.8c3.1,0,5.8,1.5,7.4,3.8H30V4.6C30,2,27.9-0.1,25.3-0.1z M27.6,5.7c0,0.5-0.4,0.8-0.8,0.8h-2.5c-0.5,0-0.8-0.4-0.8-0.8V3.2c0-0.5,0.4-0.8,0.8-0.8h2.5c0.5,0,0.8,0.4,0.8,0.8V5.7z M20.6,14.9c0-3.1-2.5-5.6-5.6-5.6c-3.1,0-5.6,2.5-5.6,5.6s2.5,5.6,5.6,5.6C18.1,20.5,20.6,18,20.6,14.9z"/>
				</svg>
			)
		case 'mail':
			return (
				<svg x="0px" y="0px" viewBox="0 0 53.3 36.1">
					<style>
						{`.st1 {fill: #FFFFFF}`}
					</style>
					<g>
						<g>
							<path style={style} d="M50.9,36.1H2.5C1.1,36.1,0,35,0,33.7V2.5C0,1.1,1.1,0,2.5,0h48.4c1.4,0,2.5,1.1,2.5,2.5v31.2C53.3,35,52.2,36.1,50.9,36.1z M4.9,31.2h43.5V4.9H4.9V31.2z"/>
						</g>
						<g>
							<path style={style} d="M50.9,36.1c-0.5,0-0.9-0.1-1.3-0.4L26.7,21L3.8,35.7c-1.1,0.7-2.7,0.4-3.4-0.7c-0.7-1.1-0.4-2.7,0.7-3.4L25.3,16c0.8-0.5,1.9-0.5,2.7,0l24.2,15.6c1.1,0.7,1.5,2.3,0.7,3.4C52.5,35.7,51.7,36.1,50.9,36.1z"/>
						</g>
						<g>
							<path className="st1" d="M2.5,2.5l20.1,18c2.3,2.1,5.9,2.1,8.3,0l20.1-18H2.5z"/>
							<path style={style} d="M26.7,24.6c-2.1,0-4.1-0.7-5.8-2.2L0.8,4.3c-0.8-0.7-1-1.8-0.7-2.7C0.5,0.6,1.4,0,2.5,0h48.4c1,0,1.9,0.6,2.3,1.6c0.4,1,0.1,2-0.7,2.7l-20.1,18C30.8,23.8,28.7,24.6,26.7,24.6z M8.9,4.9l15.3,13.7c1.4,1.3,3.5,1.3,5,0L44.4,4.9H8.9z"/>
						</g>
					</g>
				</svg>
			)
		case 'logo':
			return (
				<svg x="0px" y="0px" viewBox="0 0 200.3 131.2">
					<g>
						<path style={style} d="M100.2,90.6c-0.9,0-1.7-0.5-2.1-1.2L48.6,3.7c-0.4-0.8-0.4-1.7,0-2.5C49,0.5,49.8,0,50.7,0h98.9c0.9,0,1.7,0.5,2.1,1.2c0.4,0.8,0.4,1.7,0,2.5l-49.5,85.7C101.9,90.2,101,90.6,100.2,90.6z M55,4.9l45.2,78.3l45.2-78.3H55z"/>
					</g>
					<g>
						<path className="st0" d="M0,113.8h7c3.3,0,5.8,1.9,5.8,5.4c0,2.6-1.8,5.3-5.6,5.3H3.2v6.4H0V113.8z M6.6,121.9c1.8,0,3.1-0.9,3.1-2.7c0-2-1.3-2.7-3.1-2.7H3.2v5.3H6.6z"/>
						<path className="st0" d="M17.4,125v-11.2h3.2v10.8c0,2.1,1,3.7,3.5,3.7c2.3,0,3.5-1.2,3.5-3.7v-10.8h3.2v11c0,4.1-2.7,6.4-6.7,6.4S17.4,129.2,17.4,125z"/>
						<path className="st0" d="M36.5,113.8h3.1l7.4,11.7h0v-11.7h3.1V131h-3.1l-7.4-11.7h0V131h-3.1V113.8z"/>
						<path className="st0" d="M60.9,113.8h2.9l6.3,17.1h-3.4l-1.2-3.6H59l-1.3,3.6h-3.1L60.9,113.8z M64.6,124.6l-2.3-6.9h0l-2.4,6.9H64.6z"/>
						<path className="st0" d="M74.6,113.8h3.1l7.4,11.7h0v-11.7h3.1V131h-3.1l-7.4-11.7h0V131h-3.1V113.8z"/>
						<path className="st0" d="M94.1,113.8h3.2V131h-3.2V113.8z"/>
						<path className="st0" d="M103.1,113.8h3.9l4.4,10.7h0l4.3-10.7h3.9V131h-3.1v-11.4h0l-3.9,9.4h-2.5l-3.9-9.4h0V131h-3.1V113.8z"/>
						<path className="st0" d="M130.4,113.8h2.9l6.3,17.1h-3.4l-1.2-3.6h-6.5l-1.3,3.6h-3.1L130.4,113.8z M134.1,124.6l-2.3-6.9h0l-2.4,6.9H134.1z"/>
						<path className="st0" d="M146,116.6H141v-2.7h13v2.7h-4.9V131H146V116.6z"/>
						<path className="st0" d="M158.8,113.8h3.2V131h-3.2V113.8z"/>
						<path className="st0" d="M167.4,125.3v-5.5c0-4.1,3.5-6.3,6.8-6.3c3.7,0,7,2.4,7,6.2v5.1c0,4.1-3.5,6.3-6.8,6.3C170.7,131.2,167.4,129.2,167.4,125.3z M178.1,125.1v-5.5c0-2.1-1.8-3.2-3.8-3.2c-1.6,0-3.7,0.9-3.7,3.3v5.5c0,2.1,1.8,3.2,3.8,3.2C176,128.4,178.1,127.5,178.1,125.1z"/>
						<path className="st0" d="M186.7,113.8h3.1l7.4,11.7h0v-11.7h3.1V131h-3.1l-7.4-11.7h0V131h-3.1V113.8z"/>
					</g>
				</svg>
			)
		case 'search':
			localStyle = {
				pointerEvents: 'none',
				display: 'block',
				width: '100%',
				height: '100%'
			}
			return (
				<svg viewBox="0 0 24 24" focusable="false" style={localStyle}>
					<g>
						<path style={style} d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
					</g>
				</svg>
			)
		case 'link':
			return (
<svg x="0px" y="0px" viewBox="0 0 60 60">
<g>
<path style={style} d="M30,0C13.4,0,0,13.4,0,30s13.4,30,30,30s30-13.4,30-30S46.6,0,30,0z M32.6,40.5C32.5,40.5,32.5,40.6,32.6,40.5
c-1.6,1.6-3.2,3.2-4.8,4.8c-0.8,0.8-1.5,1.6-2.5,2.1c-0.6,0.3-1.2,0.6-1.9,0.7c-0.6,0.1-1.3,0.2-2,0.2c-0.7-0.1-1.3-0.2-1.9-0.4
c-0.4-0.2-0.8-0.3-1.2-0.6c-0.4-0.2-0.7-0.5-1-0.7c-0.3-0.3-0.6-0.6-0.9-0.8c-0.5-0.5-0.9-0.9-1.4-1.4c-0.9-0.9-2-1.9-2.6-3.1
c-0.3-0.6-0.5-1.2-0.6-1.9c-0.1-0.4-0.1-0.9-0.1-1.3c0-0.4,0.1-0.8,0.1-1.2c0.1-0.7,0.4-1.3,0.6-1.9c0.3-0.6,0.7-1.2,1.2-1.7
c0.5-0.5,1-1,1.5-1.5c1.4-1.4,2.9-2.9,4.3-4.3c0.9-0.9,2.5-1,3.5,0c0.9,0.9,1,2.5,0,3.5c-0.6,0.6-1.1,1.1-1.7,1.7
c-0.9,0.9-1.8,1.8-2.8,2.8c-0.5,0.5-1,0.9-1.4,1.4c-0.1,0.2-0.2,0.4-0.3,0.6c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0,0.4,0,0.6
c0,0.2,0.1,0.3,0.1,0.5c0.1,0.2,0.2,0.4,0.3,0.6c0.2,0.2,0.3,0.4,0.5,0.6c0.4,0.4,0.8,0.8,1.2,1.2c0.4,0.4,0.8,0.8,1.2,1.2
c0.2,0.2,0.4,0.4,0.6,0.5c0.2,0.1,0.4,0.2,0.6,0.3c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.4,0,0.6,0c0.2,0,0.3-0.1,0.5-0.1
c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.2,0.3-0.3,0.5-0.5c0.4-0.4,0.7-0.7,1.1-1.1c1.4-1.4,2.9-2.9,4.3-4.3c0.9-0.9,2.5-1,3.5,0
C33.5,38,33.5,39.6,32.6,40.5z M30.7,32.7c-1.1,1.1-2.2,2.2-3.3,3.3c-0.9,0.9-2.5,1-3.5,0c-0.9-0.9-1-2.5,0-3.5
c0.4-0.4,0.9-0.8,1.3-1.3c1.8-1.8,3.6-3.5,5.4-5.3c1.1-1.1,2.2-2.2,3.3-3.3c0.9-0.9,2.5-1,3.5,0c0.9,0.9,1,2.5,0,3.5
c-0.4,0.4-0.9,0.8-1.3,1.3C34.2,29.2,32.5,31,30.7,32.7z M48.2,23.2c-0.1,0.7-0.4,1.3-0.6,1.9c-0.3,0.6-0.7,1.2-1.2,1.7
c-0.5,0.5-1,1-1.5,1.5c-1.4,1.4-2.9,2.9-4.3,4.3c-0.9,0.9-2.5,1-3.5,0c-0.9-0.9-1-2.5,0-3.5c0.6-0.6,1.1-1.1,1.7-1.7
c0.9-0.9,1.8-1.8,2.8-2.8c0.5-0.5,1-0.9,1.4-1.4c0.1-0.2,0.2-0.4,0.3-0.6c0-0.1,0.1-0.3,0.1-0.4c0-0.2,0-0.4,0-0.6
c0-0.2-0.1-0.3-0.1-0.5c-0.1-0.2-0.2-0.4-0.3-0.6c-0.2-0.2-0.3-0.4-0.5-0.6c-0.4-0.4-0.8-0.8-1.2-1.2c-0.4-0.4-0.8-0.8-1.2-1.2
c-0.2-0.2-0.4-0.4-0.6-0.5c-0.2-0.1-0.4-0.2-0.6-0.3c-0.1,0-0.3-0.1-0.4-0.1c-0.2,0-0.4,0-0.6,0c-0.2,0-0.3,0.1-0.5,0.1
c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.2-0.3,0.3-0.5,0.5c-0.4,0.4-0.7,0.7-1.1,1.1c-1.4,1.4-2.9,2.9-4.3,4.3c-0.9,0.9-2.5,1-3.5,0
c-0.9-0.9-1-2.5,0-3.5c0,0,0.1-0.1,0.1-0.1c1.6-1.6,3.1-3.1,4.7-4.7c0.8-0.8,1.5-1.6,2.5-2.1c0.6-0.3,1.2-0.6,1.9-0.7
c0.6-0.1,1.3-0.2,2-0.2c0.7,0.1,1.3,0.2,1.9,0.4c0.4,0.2,0.8,0.3,1.2,0.6c0.4,0.2,0.7,0.5,1,0.7c0.3,0.3,0.6,0.6,0.9,0.8
c0.5,0.5,0.9,0.9,1.4,1.4c0.9,0.9,2,1.9,2.6,3.1c0.3,0.6,0.5,1.2,0.6,1.9c0.1,0.4,0.1,0.9,0.1,1.3C48.3,22.4,48.2,22.8,48.2,23.2z"
/>
<path className="st0" d="M16.8,37.1C16.8,37.1,16.8,37.1,16.8,37.1C16.8,37.1,16.8,37.1,16.8,37.1C16.8,37.1,16.8,37.1,16.8,37.1z"/>
<path className="st0" d="M16.6,37.5C16.6,37.5,16.6,37.6,16.6,37.5C16.6,37.6,16.6,37.6,16.6,37.5C16.6,37.6,16.6,37.5,16.6,37.5z"/>
<ellipse transform="matrix(0.1047 -0.9945 0.9945 0.1047 -23.1228 50.6145)" className="st0" cx="16.5" cy="38.1" rx="0" ry="0"/>
<polygon className="st0" points="16.6,37.6 16.6,37.6 16.6,37.6 	"/>
<ellipse transform="matrix(0.3229 -0.9464 0.9464 0.3229 -24.3094 41.1877)" className="st0" cx="16.6" cy="37.6" rx="0" ry="0"/>
<ellipse transform="matrix(0.5463 -0.8376 0.8376 0.5463 -23.4146 30.9299)" className="st0" cx="16.8" cy="37.1" rx="0" ry="0"/>
<path className="st0" d="M16.9,37C16.9,37,16.8,37.1,16.9,37C16.8,37.1,16.8,37.1,16.9,37C16.9,37.1,16.9,37,16.9,37z"/>
<path className="st0" d="M16.6,38.8C16.6,38.8,16.6,38.7,16.6,38.8C16.6,38.7,16.6,38.7,16.6,38.8C16.6,38.7,16.6,38.7,16.6,38.8z"/>
<path className="st0" d="M16.5,38.1C16.5,38.1,16.5,38.1,16.5,38.1C16.5,38.1,16.5,38.1,16.5,38.1C16.5,38.1,16.5,38.1,16.5,38.1z"/>
<path className="st0" d="M16.8,39.2C16.8,39.2,16.8,39.2,16.8,39.2C16.8,39.2,16.8,39.2,16.8,39.2C16.8,39.2,16.8,39.2,16.8,39.2z"/>
<polygon className="st0" points="16.5,38.2 16.5,38.2 16.5,38.2 	"/>
<path className="st0" d="M16.6,38.7C16.6,38.7,16.6,38.7,16.6,38.7C16.6,38.7,16.6,38.7,16.6,38.7C16.6,38.7,16.6,38.7,16.6,38.7z"/>
<path className="st0" d="M16.6,37.6c0,0.2-0.1,0.4-0.1,0.6c0,0,0,0,0,0c0-0.1,0-0.2,0.1-0.3C16.6,37.8,16.6,37.7,16.6,37.6
C16.6,37.6,16.6,37.6,16.6,37.6z"/>
<ellipse transform="matrix(0.9592 -0.2829 0.2829 0.9592 -10.2743 6.284)" className="st0" cx="16.6" cy="38.7" rx="0" ry="0"/>
<path className="st0" d="M16.8,37.1c-0.1,0.2-0.1,0.3-0.2,0.5c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2C16.8,37.3,16.8,37.2,16.8,37.1
C16.8,37.1,16.8,37.1,16.8,37.1z"/>
<path className="st0" d="M16.9,39.3C16.9,39.3,16.9,39.3,16.9,39.3C16.8,39.2,16.8,39.2,16.9,39.3C16.8,39.3,16.9,39.3,16.9,39.3z"/>
<path className="st0" d="M16.7,37.4c0.1-0.2,0.2-0.4,0.3-0.6c-0.1,0.1-0.2,0.2-0.2,0.3C16.8,37.2,16.8,37.3,16.7,37.4z"/>
<path className="st0" d="M16.6,38.4c0-0.2,0-0.4,0-0.6c0,0.1,0,0.2-0.1,0.3C16.6,38.2,16.6,38.3,16.6,38.4z"/>
<path className="st0" d="M16.8,37.1C16.8,37.1,16.8,37.1,16.8,37.1c0.1-0.1,0.2-0.2,0.2-0.2c0-0.1,0.1-0.2,0.1-0.2
C17.1,36.8,17,36.9,16.8,37.1z"/>
<path className="st0" d="M16.6,37.9c0-0.1,0.1-0.3,0.1-0.4c0,0.1-0.1,0.1-0.1,0.2C16.6,37.7,16.6,37.8,16.6,37.9z"/>
<path className="st0" d="M16.8,39.3c0.1,0.1,0.2,0.3,0.3,0.4c-0.1-0.1-0.1-0.2-0.1-0.2C17,39.4,16.9,39.3,16.8,39.3
C16.8,39.2,16.8,39.2,16.8,39.3z"/>
<path className="st0" d="M16.8,39.2C16.8,39.2,16.8,39.2,16.8,39.2C16.8,39.2,16.8,39.2,16.8,39.2C16.8,39.2,16.8,39.2,16.8,39.2
C16.8,39.2,16.8,39.2,16.8,39.2z"/>
<path className="st0" d="M17.1,39.5c-0.1-0.2-0.2-0.4-0.3-0.6c0,0.1,0.1,0.2,0.1,0.3C16.9,39.3,17,39.4,17.1,39.5z"/>
<path className="st0" d="M16.7,38.9c0-0.2-0.1-0.3-0.1-0.5c0,0.1,0,0.2,0,0.3C16.7,38.8,16.7,38.8,16.7,38.9z"/>
<path className="st0" d="M16.6,38.7c0.1,0.2,0.1,0.3,0.2,0.5c0,0,0,0,0,0c0-0.1-0.1-0.2-0.1-0.3C16.7,38.8,16.7,38.8,16.6,38.7
C16.6,38.7,16.6,38.7,16.6,38.7z"/>
<path className="st0" d="M16.5,38.2c0,0.2,0,0.4,0.1,0.6c0,0,0,0,0,0c0-0.1,0-0.2,0-0.3C16.6,38.3,16.6,38.2,16.5,38.2
C16.6,38.1,16.5,38.2,16.5,38.2z"/>
<path className="st0" d="M22.9,43.2C22.9,43.2,22.9,43.2,22.9,43.2C22.9,43.2,22.9,43.2,22.9,43.2C22.9,43.2,22.9,43.2,22.9,43.2z"/>
<path className="st0" d="M21.2,43.4C21.2,43.4,21.3,43.4,21.2,43.4C21.3,43.4,21.3,43.4,21.2,43.4C21.2,43.4,21.2,43.4,21.2,43.4z"/>
<path className="st0" d="M20.8,43.2C20.8,43.2,20.8,43.2,20.8,43.2C20.8,43.2,20.8,43.2,20.8,43.2C20.8,43.2,20.8,43.2,20.8,43.2z"/>
<polygon className="st0" points="21.3,43.4 21.3,43.4 21.3,43.4 	"/>
<path className="st0" d="M20.7,43.1C20.7,43.1,20.7,43.2,20.7,43.1C20.8,43.2,20.8,43.2,20.7,43.1C20.7,43.1,20.7,43.1,20.7,43.1z"/>
<ellipse transform="matrix(0.5408 -0.8412 0.8412 0.5408 -26.7682 37.2866)" className="st0" cx="20.8" cy="43.2" rx="0" ry="0"/>
<path className="st0" d="M21.8,43.5C21.8,43.5,21.8,43.5,21.8,43.5C21.8,43.5,21.8,43.5,21.8,43.5C21.8,43.5,21.8,43.5,21.8,43.5z"/>
<path className="st0" d="M23,43.1C23,43.1,23,43.1,23,43.1C22.9,43.2,22.9,43.2,23,43.1C22.9,43.2,23,43.1,23,43.1z"/>
<path className="st0" d="M21.1,43.3c-0.2-0.1-0.4-0.2-0.6-0.3c0.1,0.1,0.2,0.2,0.2,0.2C20.9,43.2,21,43.2,21.1,43.3z"/>
<ellipse transform="matrix(0.3229 -0.9464 0.9464 0.3229 -26.6458 49.4983)" className="st0" cx="21.3" cy="43.4" rx="0" ry="0"/>
<path className="st0" d="M22.4,43.4C22.4,43.4,22.4,43.4,22.4,43.4C22.4,43.4,22.4,43.4,22.4,43.4C22.4,43.4,22.4,43.4,22.4,43.4z"/>
<path className="st0" d="M22.4,43.4C22.4,43.4,22.4,43.4,22.4,43.4C22.4,43.4,22.4,43.4,22.4,43.4C22.4,43.4,22.4,43.4,22.4,43.4z"/>
<polygon className="st0" points="21.8,43.5 21.8,43.5 21.8,43.5 	"/>
<path className="st0" d="M21.3,43.4c0.2,0,0.4,0.1,0.6,0.1c0,0,0,0,0,0c-0.1,0-0.2,0-0.3-0.1C21.5,43.4,21.4,43.4,21.3,43.4
C21.3,43.4,21.3,43.4,21.3,43.4z"/>
<path className="st0" d="M22.1,43.4c-0.2,0-0.4,0-0.6,0c0.1,0,0.2,0,0.3,0.1C21.9,43.4,22,43.4,22.1,43.4z"/>
<path className="st0" d="M21.8,43.5c0.2,0,0.4,0,0.6-0.1c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0C22,43.4,21.9,43.4,21.8,43.5
C21.8,43.4,21.8,43.5,21.8,43.5z"/>
<path className="st0" d="M20.8,43.2c0.2,0.1,0.3,0.1,0.5,0.2c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1C21,43.2,20.9,43.2,20.8,43.2
C20.8,43.2,20.8,43.2,20.8,43.2z"/>
<path className="st0" d="M21.5,43.4c-0.1,0-0.3-0.1-0.4-0.1c0.1,0,0.1,0.1,0.2,0.1C21.4,43.4,21.5,43.4,21.5,43.4z"/>
<path className="st0" d="M22.6,43.3c-0.2,0-0.3,0.1-0.5,0.1c0.1,0,0.2,0,0.3,0C22.5,43.3,22.5,43.3,22.6,43.3z"/>
<path className="st0" d="M22.9,43.2C22.9,43.2,22.9,43.2,22.9,43.2C22.9,43.2,22.9,43.2,22.9,43.2C22.9,43.2,22.9,43.2,22.9,43.2
C22.9,43.2,22.9,43.2,22.9,43.2z"/>
<ellipse transform="matrix(0.1054 -0.9944 0.9944 0.1054 -23.6733 60.5865)" className="st0" cx="21.8" cy="43.5" rx="0" ry="0"/>
<ellipse transform="matrix(0.9592 -0.2829 0.2829 0.9592 -11.3554 8.1103)" className="st0" cx="22.4" cy="43.4" rx="0" ry="0"/>
<path className="st0" d="M23.2,42.9c-0.2,0.1-0.4,0.2-0.6,0.3c0.1,0,0.2-0.1,0.3-0.1C23,43.1,23.1,43,23.2,42.9z"/>
<path className="st0" d="M22.4,43.4c0.2-0.1,0.3-0.1,0.5-0.2c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.3,0.1C22.5,43.3,22.5,43.3,22.4,43.4
C22.4,43.4,22.4,43.4,22.4,43.4z"/>
<path className="st0" d="M22.9,43.2c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0.1-0.2,0.1-0.2,0.1C23.1,43,23,43.1,22.9,43.2
C22.9,43.2,22.9,43.2,22.9,43.2z"/>
<path className="st0" d="M20.8,43.2C20.8,43.2,20.8,43.2,20.8,43.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.2-0.1
C20.4,42.9,20.6,43,20.8,43.2z"/>
<ellipse transform="matrix(0.5463 -0.8376 0.8376 0.5463 0.3821 46.5507)" className="st0" cx="43.2" cy="22.9" rx="0" ry="0"/>
<ellipse transform="matrix(0.3189 -0.9478 0.9478 0.3189 8.2933 56.3753)" className="st0" cx="43.4" cy="22.4" rx="0" ry="0"/>
<path className="st0" d="M43.1,23C43.1,23,43.2,22.9,43.1,23C43.2,22.9,43.2,22.9,43.1,23C43.1,22.9,43.1,23,43.1,23z"/>
<path className="st0" d="M43.1,20.7C43.1,20.7,43.1,20.7,43.1,20.7C43.2,20.8,43.2,20.8,43.1,20.7C43.2,20.7,43.1,20.7,43.1,20.7z"/>
<path className="st0" d="M43.2,22.9C43.2,22.9,43.2,22.9,43.2,22.9C43.2,22.9,43.2,22.9,43.2,22.9C43.2,22.9,43.2,22.9,43.2,22.9z"/>
<ellipse transform="matrix(0.1047 -0.9945 0.9945 0.1047 17.1715 62.7747)" className="st0" cx="43.5" cy="21.9" rx="0" ry="0"/>
<path className="st0" d="M43.5,21.9C43.5,21.9,43.5,21.9,43.5,21.9C43.5,21.8,43.5,21.9,43.5,21.9C43.5,21.9,43.5,21.9,43.5,21.9z"/>
<path className="st0" d="M43.4,21.2C43.4,21.2,43.4,21.3,43.4,21.2C43.4,21.3,43.4,21.3,43.4,21.2C43.4,21.3,43.4,21.3,43.4,21.2z"/>
<path className="st0" d="M43.2,20.8C43.2,20.8,43.2,20.8,43.2,20.8C43.2,20.8,43.2,20.8,43.2,20.8C43.2,20.8,43.2,20.8,43.2,20.8z"/>
<path className="st0" d="M43.4,21.3C43.4,21.3,43.4,21.3,43.4,21.3C43.4,21.3,43.4,21.3,43.4,21.3C43.4,21.3,43.4,21.3,43.4,21.3z"/>
<path className="st0" d="M43.2,20.8C43.2,20.8,43.2,20.8,43.2,20.8C43.2,20.8,43.2,20.8,43.2,20.8C43.2,20.8,43.2,20.8,43.2,20.8
C43.2,20.8,43.2,20.8,43.2,20.8z"/>
<polygon className="st0" points="43.5,21.8 43.5,21.8 43.5,21.8 	"/>
<polygon className="st0" points="43.4,22.4 43.4,22.4 43.4,22.4 	"/>
<ellipse transform="matrix(0.9603 -0.2788 0.2788 0.9603 -4.2129 12.9377)" className="st0" cx="43.4" cy="21.3" rx="0" ry="0"/>
<path className="st0" d="M43.3,22.6c-0.1,0.2-0.2,0.4-0.3,0.6c0.1-0.1,0.2-0.2,0.2-0.3C43.2,22.8,43.2,22.7,43.3,22.6z"/>
<path className="st0" d="M43.4,22.4c0-0.2,0.1-0.4,0.1-0.6c0,0,0,0,0,0c0,0.1,0,0.2-0.1,0.3C43.4,22.2,43.4,22.3,43.4,22.4
C43.4,22.4,43.4,22.4,43.4,22.4z"/>
<path className="st0" d="M43.4,22.1c0,0.1-0.1,0.3-0.1,0.4c0-0.1,0.1-0.1,0.1-0.2C43.4,22.3,43.4,22.2,43.4,22.1z"/>
<path className="st0" d="M43.2,22.9c0.1-0.2,0.1-0.3,0.2-0.5c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0.2C43.2,22.7,43.2,22.8,43.2,22.9
C43.2,22.9,43.2,22.9,43.2,22.9z"/>
<path className="st0" d="M43.2,22.9C43.2,22.9,43.2,22.9,43.2,22.9c-0.1,0.1-0.2,0.2-0.2,0.2c0,0.1-0.1,0.2-0.1,0.2
C42.9,23.2,43,23.1,43.2,22.9z"/>
<path className="st0" d="M43.4,21.3c-0.1-0.2-0.1-0.3-0.2-0.5c0,0,0,0,0,0c0,0.1,0.1,0.2,0.1,0.3C43.3,21.2,43.3,21.2,43.4,21.3
C43.4,21.3,43.4,21.3,43.4,21.3z"/>
<path className="st0" d="M43.4,21.6c0,0.2,0,0.4,0,0.6c0-0.1,0-0.2,0.1-0.3C43.4,21.8,43.4,21.7,43.4,21.6z"/>
<path className="st0" d="M43.2,20.7c-0.1-0.1-0.2-0.3-0.3-0.4c0.1,0.1,0.1,0.2,0.1,0.2C43,20.6,43.1,20.7,43.2,20.7
C43.2,20.8,43.2,20.8,43.2,20.7z"/>
<path className="st0" d="M42.9,20.5c0.1,0.2,0.2,0.4,0.3,0.6c0-0.1-0.1-0.2-0.1-0.3C43.1,20.7,43,20.6,42.9,20.5z"/>
<path className="st0" d="M43.3,21.1c0,0.2,0.1,0.3,0.1,0.5c0-0.1,0-0.2,0-0.3C43.3,21.2,43.3,21.2,43.3,21.1z"/>
<path className="st0" d="M43.5,21.8c0-0.2,0-0.4-0.1-0.6c0,0,0,0,0,0c0,0.1,0,0.2,0,0.3C43.4,21.7,43.4,21.8,43.5,21.8
C43.4,21.9,43.5,21.8,43.5,21.8z"/>
<path className="st0" d="M43.4,22.5C43.4,22.5,43.4,22.4,43.4,22.5C43.4,22.4,43.4,22.4,43.4,22.5C43.4,22.4,43.4,22.5,43.4,22.5z"/>
<path className="st0" d="M39.2,16.8C39.2,16.8,39.2,16.8,39.2,16.8C39.2,16.8,39.2,16.8,39.2,16.8C39.2,16.8,39.2,16.8,39.2,16.8z"/>
<path className="st0" d="M39.3,16.9C39.3,16.9,39.3,16.8,39.3,16.9C39.2,16.8,39.2,16.8,39.3,16.9C39.3,16.9,39.3,16.9,39.3,16.9z"/>
<path className="st0" d="M39.2,16.8C39.2,16.8,39.2,16.8,39.2,16.8c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.1,0.2,0.1
C39.6,17.1,39.4,17,39.2,16.8z"/>
<ellipse transform="matrix(9.820717e-02 -0.9952 0.9952 9.820717e-02 17.9467 52.9038)" className="st0" cx="38.2" cy="16.5" rx="0" ry="0"/>
<ellipse transform="matrix(0.5408 -0.8412 0.8412 0.5408 3.8522 40.7387)" className="st0" cx="39.2" cy="16.8" rx="0" ry="0"/>
<ellipse transform="matrix(0.3189 -0.9478 0.9478 0.3189 10.6193 48.0357)" className="st0" cx="38.7" cy="16.6" rx="0" ry="0"/>
<path className="st0" d="M38.8,16.6C38.8,16.6,38.7,16.6,38.8,16.6C38.7,16.6,38.7,16.6,38.8,16.6C38.8,16.6,38.8,16.6,38.8,16.6z"/>
<path className="st0" d="M37.1,16.8C37.1,16.8,37.1,16.8,37.1,16.8C37.1,16.8,37.1,16.8,37.1,16.8C37.1,16.8,37.1,16.8,37.1,16.8z"/>
<path className="st0" d="M37,16.9C37,16.9,37,16.9,37,16.9C37.1,16.8,37.1,16.8,37,16.9C37.1,16.8,37,16.9,37,16.9z"/>
<path className="st0" d="M38.2,16.5C38.2,16.5,38.2,16.5,38.2,16.5C38.2,16.5,38.2,16.5,38.2,16.5C38.2,16.5,38.2,16.5,38.2,16.5z"/>
<path className="st0" d="M37.6,16.6C37.6,16.6,37.6,16.6,37.6,16.6C37.6,16.6,37.6,16.6,37.6,16.6C37.6,16.6,37.6,16.6,37.6,16.6z"/>
<polygon className="st0" points="38.2,16.5 38.2,16.5 38.2,16.5 	"/>
<path className="st0" d="M37.6,16.6C37.6,16.6,37.6,16.6,37.6,16.6C37.6,16.6,37.6,16.6,37.6,16.6C37.6,16.6,37.6,16.6,37.6,16.6z"/>
<polygon className="st0" points="38.7,16.6 38.7,16.6 38.7,16.6 	"/>
<path className="st0" d="M38.7,16.6c-0.2,0-0.4-0.1-0.6-0.1c0,0,0,0,0,0c0.1,0,0.2,0,0.3,0.1C38.5,16.6,38.6,16.6,38.7,16.6
C38.7,16.6,38.7,16.6,38.7,16.6z"/>
<path className="st0" d="M37.9,16.6c0.2,0,0.4,0,0.6,0c-0.1,0-0.2,0-0.3-0.1C38.1,16.6,38,16.6,37.9,16.6z"/>
<path className="st0" d="M38.5,16.6c0.1,0,0.3,0.1,0.4,0.1c-0.1,0-0.1-0.1-0.2-0.1C38.6,16.6,38.5,16.6,38.5,16.6z"/>
<path className="st0" d="M39.2,16.8c-0.2-0.1-0.3-0.1-0.5-0.2c0,0,0,0,0,0c0.1,0,0.1,0.1,0.2,0.1C39,16.8,39.1,16.8,39.2,16.8
C39.2,16.8,39.2,16.8,39.2,16.8z"/>
<path className="st0" d="M38.9,16.7c0.2,0.1,0.4,0.2,0.6,0.3c-0.1-0.1-0.1-0.2-0.2-0.2C39.1,16.8,39,16.8,38.9,16.7z"/>
<ellipse transform="matrix(0.9592 -0.2829 0.2829 0.9592 -3.1669 11.3135)" className="st0" cx="37.6" cy="16.6" rx="0" ry="0"/>
<path className="st0" d="M38.2,16.5c-0.2,0-0.4,0-0.6,0.1c0,0,0,0,0,0c0.1,0,0.2,0,0.3,0C38,16.6,38.1,16.6,38.2,16.5
C38.2,16.6,38.2,16.5,38.2,16.5z"/>
<path className="st0" d="M36.8,17.1c0.2-0.1,0.4-0.2,0.6-0.3c-0.1,0-0.2,0.1-0.3,0.1C37,16.9,36.9,17,36.8,17.1z"/>
<path className="st0" d="M37.1,16.8C37.1,16.8,37.1,16.8,37.1,16.8C37.1,16.8,37.1,16.8,37.1,16.8C37.1,16.8,37.1,16.8,37.1,16.8
C37.1,16.8,37.1,16.8,37.1,16.8z"/>
<path className="st0" d="M37.1,16.8c-0.1,0.1-0.3,0.2-0.4,0.3c0.1-0.1,0.2-0.1,0.2-0.1C36.9,17,37,16.9,37.1,16.8
C37.1,16.8,37.1,16.8,37.1,16.8z"/>
<path className="st0" d="M37.6,16.6c-0.2,0.1-0.3,0.1-0.5,0.2c0,0,0,0,0,0c0.1,0,0.2-0.1,0.3-0.1C37.5,16.7,37.5,16.7,37.6,16.6
C37.6,16.6,37.6,16.6,37.6,16.6z"/>
<path className="st0" d="M37.4,16.7c0.2,0,0.3-0.1,0.5-0.1c-0.1,0-0.2,0-0.3,0C37.5,16.7,37.5,16.7,37.4,16.7z"/>
</g>
</svg>
			)
		default:
	}
}
